
class IntersectTest {
  // Constructor always gets called, pass initial params here
  constructor () {
    this.initialize()
  }

  initialize () {
    const homepageObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view')
          // entry.target.src = entry.target.dataset.src
          // observer.unobserve(entry.target)
        } else {
          entry.target.classList.remove('in-view')
        }
      })
    }, { rootMargin: '50px 0% 0% 0%' })
    const homeEl = document.querySelectorAll('.salespage-block')

    console.log(homeEl)

    homeEl.forEach((item) => {
      console.log(item)
      homepageObserver.observe(item)
      item.classList.add('is-intersected')
    })
  }
}

export { IntersectTest }
