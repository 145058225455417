
class TestimonialsWidget {

  testimonialsBlock = document.querySelector('.testimonial-widget')
  currentIndex = 0;
  totalNrSlides = 0;
  slides = []
  navPrev;
  navNext;

  // Constructor always gets called, pass initial params here
  constructor () {
    if(!document.querySelector('.testimonial-widget')) return


    this.initialize()
  }

  addNavigation () {
    this.navPrev.addEventListener('click', (e) => {
      this.currentIndex = this.currentIndex <= 0 ? 0 : this.currentIndex - 1
      this.navigateTo(this.currentIndex)
    })

    this.navNext.addEventListener('click', (e) => {
      this.currentIndex = this.currentIndex <= (this.totalNrSlides - 2) ? this.currentIndex + 1 : this.currentIndex
      this.navigateTo(this.currentIndex)
    })
  }

  navigateTo (slideIndex: number) {
    const listContainer: HTMLDivElement = this.testimonialsBlock.querySelector('.testimonial-widget__item-wrapper')
    listContainer.style.transform = `translateX(-${slideIndex*100}%)`

    this.updateNavigationView()
    this.setActiveItem()
  }

  updateNavigationView () {
    if(this.currentIndex === 0) {
      this.navPrev.classList.add('disabled')
    } else {
      this.navPrev.classList.remove('disabled')
    }
    if(this.currentIndex === this.totalNrSlides - 1) {
      this.navNext.classList.add('disabled')
    } else {
      this.navNext.classList.remove('disabled')
    }
  }

  setActiveItem () {
    for(const item of this.slides) {
      item.classList.remove('active')
    }
    this.slides[this.currentIndex].classList.add('active')
  }

  initialize () {
    for(const item of this.testimonialsBlock.querySelectorAll('article')) {
      this.slides.push(item)
    }

    this.totalNrSlides = this.testimonialsBlock.querySelectorAll('article').length
    this.navPrev = this.testimonialsBlock.querySelector('.navigation__prev');
    this.navNext = this.testimonialsBlock.querySelector('.navigation__next');

    this.addNavigation()
    this.updateNavigationView()
    this.setActiveItem()
  }
}

export { TestimonialsWidget }
