
class TestimonialsSlider {

  testimonialsBlock = document.querySelector('.salespage__references')
  currentIndex = 0;
  totalNrSlides = 0;
  slides = []
  navPrev = document.createElement('div')
  navNext = document.createElement('div')

  // Constructor always gets called, pass initial params here
  constructor () {
    if(!document.querySelector('.salespage__references')) return


    this.initialize()
  }

  addNavigation () {
    const navWrapper = document.createElement('div')
    navWrapper.className = 'testimonials-nav'


    // BUTTONS AND IMAGES
    // this.navPrev = document.createElement('div')
    this.navPrev.className = 'testimonials-nav__prev'
    this.navNext.className = 'testimonials-nav__next'

    const chevronLeft = document.createElement('img')
    chevronLeft.src = '/wp-content/themes/p2p/img/icons/chevron-left.svg'

    const chevronRight = document.createElement('img')
    chevronRight.src = '/wp-content/themes/p2p/img/icons/chevron-right.svg'

    this.navPrev.appendChild(chevronLeft)
    this.navNext.appendChild(chevronRight)


    // APPEND ITEMS
    this.testimonialsBlock.append(navWrapper)

    navWrapper.append(this.navPrev)
    navWrapper.append(this.navNext)

    // ADD EVENTS
    this.navPrev.addEventListener('click', (e) => {
      this.currentIndex = this.currentIndex <= 0 ? 0 : this.currentIndex - 1
      this.navigateTo(this.currentIndex)
    })

    this.navNext.addEventListener('click', (e) => {
      this.currentIndex = this.currentIndex >= this.totalNrSlides ? 0 : this.currentIndex + 1
      this.navigateTo(this.currentIndex)
    })

  }

  navigateTo (slideIndex: number) {

    const listContainer: HTMLUListElement = this.testimonialsBlock.querySelector('.wp-block-post-template')
    listContainer.style.transform = `translateX(-${slideIndex*100}%)`

    this.updateNavigationView()
    this.setActiveItem()
  }

  updateNavigationView () {
    if(this.currentIndex === 0) {
      this.navPrev.classList.add('disabled')
    } else {
      this.navPrev.classList.remove('disabled')
    }
    if(this.currentIndex === this.totalNrSlides - 1) {
      this.navNext.classList.add('disabled')
    } else {
      this.navNext.classList.remove('disabled')
    }
  }

  setActiveItem () {
    for(const item of this.slides) {
      item.classList.remove('active')
    }
    this.slides[this.currentIndex].classList.add('active')
  }

  initialize () {
    for(const item of this.testimonialsBlock.querySelectorAll('li')) {
      this.slides.push(item)
    }

    this.totalNrSlides = this.testimonialsBlock.querySelectorAll('li').length
    this.addNavigation()
    this.updateNavigationView()
    this.setActiveItem()
  }
}

export { TestimonialsSlider }
